import style from './style';

const Logo = () => (
	<div className={style.logo}>

		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 704 385">
			<g opacity=".5">
				<path d="M638 106H64.6c-2.8 0-5-2.7-5-6.1s2.3-6.1 5-6.1H638c2.8 0 5 2.7 5 6.1.1 3.3-2.2 6.1-5 6.1zM638 210.9H64.6c-2.8 0-5-2.7-5-6.1 0-3.4 2.3-6.1 5-6.1H638c2.8 0 5 2.7 5 6.1.1 3.4-2.2 6.1-5 6.1z" />
			</g>
		</svg>

		<svg className={style.sectionA} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 704 385">
			<path fill="#ffffff" d="M648.8.6H55.3A54.2 54.2 0 0 0 1.1 54.8v192.4a54.2 54.2 0 0 0 54.2 54.2h402.8c3.9 0 7-3.2 7-7s-3.2-7-7-7H55.3c-22.2 0-40.2-18-40.2-40.2V54.8c0-22.2 18-40.2 40.2-40.2h593.5c22.2 0 40.2 18 40.2 40.2v192.4c0 22.2-18 40.2-40.2 40.2h-41.1c-3.9 0-7 3.2-7 7s3.2 7 7 7h41.1a54.2 54.2 0 0 0 54.2-54.2V54.8A54.2 54.2 0 0 0 648.8.6z" />
			<g>
				<path fill="#ffffff" d="M456.5 204.1L412.2 118v88c0 2.7-2.7 4.1-5.3 4.1-2.7 0-5.3-1.4-5.3-4.1V97.7c0-2.9 2.5-4 5.3-4 3.8 0 6.2 3.3 8.3 7.3l43 84.7V97.5c0-2.7 2.5-3.8 5.3-3.8 2.5 0 5.3 1.1 5.3 3.8V206c0 2.7-2.7 4.1-5.3 4.1-3 .1-5.4-2.8-7-6zM534.1 170.3v5.8c0 16.4-8.3 23.9-22.1 23.9s-22.1-7.5-22.1-23.9v-48.3c0-16.2 8.3-23.7 22.1-23.7s22.1 7.5 22.1 23.7v24.9c0 2.4 1.1 5.3 5.1 5.4 5.5.1 5.1-4.8 5.1-7.4v-22.9c0-24.2-14.2-33.2-32.4-33.2s-32.4 9-32.4 33.2v48.3c0 24.3 14.2 33.4 32.4 33.4s32.4-9 32.4-33.4v-5.7c0-2.1 0-6.4-5.3-6.4-4.9 0-4.9 4-4.9 6.3zM580.4 125.1v81.6c0 2.7 2.5 4.1 5.2 4.1 2.5 0 5.2-1.4 5.2-4.1v-82c0-2 0-9.5-5.2-9.5-5.7 0-5.2 6.3-5.2 9.9zM617 95.9h-63c-2.7 0-3.9 2.5-3.9 4.9 0 2.5 1.4 4.9 3.9 4.9h63c2.7 0 3.9-2.5 3.9-5 .1-2.5-1.2-4.8-3.9-4.8z" />
			</g>
		</svg>

		<svg className={style.sectionB} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 704 385">
			<path fill="rgb(188, 243, 249)" d="M533.2 336.5c-1.3 0-2.5-.5-3.4-1.5l-54-61.1H68.7a37.3 37.3 0 0 1-37.3-37.3V65.4a37.3 37.3 0 0 1 37.3-37.3h566.8a37.3 37.3 0 0 1 37.3 37.3v171.2a37.3 37.3 0 0 1-37.3 37.3h-45l-54 61.1c-.8 1-2.1 1.5-3.3 1.5zM68.7 37.1a28.4 28.4 0 0 0-28.4 28.4v171.2a28.4 28.4 0 0 0 28.4 28.4h409.2c1.3 0 2.5.5 3.4 1.5l51.9 58.8 51.9-58.8c.8-1 2.1-1.5 3.4-1.5h47a28.4 28.4 0 0 0 28.4-28.4V65.4A28.4 28.4 0 0 0 635.5 37H68.7v.1z" />
			<g>
				<path fill="rgb(188, 243, 249)" d="M172.4 94.7c-1.9 0-3.6.9-4 2.6l-26.9 95.4-9.7-36.3c-1.2-4.5-4.5-6-6.9-3.7-2.6 2.4-1.3 8.3-.3 12.3l10.9 40.4c.6 2.6 3.4 3.7 6.1 3.7 2.8 0 5.4-1.2 6.2-3.7l30.4-105.3c.2-.5.2-.8.2-1.2-.1-2.5-3.2-4.2-6-4.2zM124.8 135.7c.4-2.6-2-4.4-4.1-4.4-2.5 0-4.8.9-5.4 3.6l-15.5 57.9-26.9-95.4c-.5-1.7-2.2-2.6-4-2.6-2.8 0-5.9 1.7-5.9 4.2 0 .5 0 .8.2 1.2l30.6 105.3c.8 2.6 3.4 3.7 6.2 3.7s5.4-1.2 6.1-3.7c-.1 0 16.3-54.1 18.7-69.8zM189.8 95.2c-2.7 0-5.2 1.1-5.2 3.7v106.4c0 2.7 2.5 4.1 5.2 4.1 2.5 0 5.2-1.4 5.2-4.1V98.9c0-2.6-2.7-3.7-5.2-3.7zM245.3 95.2c-2.7 0-5.2 1.1-5.2 3.7v106.4c0 2.7 2.5 4.1 5.2 4.1 2.5 0 5.2-1.4 5.2-4.1V98.9c-.1-2.6-2.7-3.7-5.2-3.7zM230.5 154.5h-24.2s-3.8.4-3.8-4.5c0-4.5 3.8-4.5 3.8-4.5h24.2s4.4 0 4.4 4.5c0 5.4-4.4 4.5-4.4 4.5z" />
				<path fill="rgb(188, 243, 249)" d="M266.3 97.8c-1.1-2.2-2.4-3.1-4.1-3.1-2.5 0-5.6 1.7-5.6 4.4 0 .6.2 1.7.8 2.8l32.5 62.3c.2.5.2.8.2 1.3v39.8c0 2.7 2.7 4.1 5.2 4.1 2.7 0 5.2-1.4 5.2-4.1v-39.8c0-.7-.8-4-4.3-9.9a836.4 836.4 0 0 1-29.9-57.8zM328.3 94.7c-1.6 0-3 .9-4.1 3.1L300 145.5c-2.2 5.9 4.9 10.6 6.5 7.5l26.8-51.1a7 7 0 0 0 .6-2.8c0-2.7-3.1-4.4-5.6-4.4z" />
			</g>
			<g>
				<path fill="rgb(188, 243, 249)" d="M684.6 356.8v26.9c0 .9.8 1.3 1.7 1.3.8 0 1.7-.5 1.7-1.3v-27.1c0-.7 0-3.1-1.7-3.1-1.9 0-1.7 2.1-1.7 3.3zM696.7 347.1h-20.8c-.9 0-1.3.8-1.3 1.6s.5 1.6 1.3 1.6h20.8c.9 0 1.3-.8 1.3-1.7 0-.7-.4-1.5-1.3-1.5z" />
				<path fill="rgb(188, 243, 249)" d="M663.7 374.9c0-11.5-17.2-5.9-17.2-18.6 0-7.6 6.2-9.5 11-9.5 3.9 0 8.7 1.3 8.7 3.2 0 .7-.6 1.9-1.5 1.9-1 0-2.7-2-7.3-2-3.8 0-7.5 1.4-7.5 6 0 9.7 17.2 4.2 17.2 18.6 0 7.4-4.6 10.7-11 10.7-6.6 0-10.6-3.7-10.6-5.6 0-.8.8-1.9 1.6-1.9 1.4 0 2.4 4.3 8.9 4.3 4.8.1 7.7-2.4 7.7-7.1z" />
				<path fill="rgb(188, 243, 249)" d="M506 347.1h-9.6c-.9.1-1.5.6-1.5 1.2v35.4c0 .9.8 1.3 1.7 1.3.8 0 1.7-.5 1.7-1.3v-33.4h7.5c4.6 0 7.4 2.5 7.4 7.9v.6c0 5.4-2.8 7.9-7.4 7.9h-3.1c-.6 0-2.1-.1-2.1 1.5 0 1.5 1.4 1.5 2 1.5h3.2c6.1 0 10.8-3 10.8-11.1v-.5c.1-8-4.6-11-10.6-11z" />
				<path fill="rgb(188, 243, 249)" d="M538.9 372.2v1.9c0 5.5-2.8 7.9-7.4 7.9s-7.4-2.5-7.4-7.9V358c0-5.4 2.8-7.9 7.4-7.9s7.4 2.5 7.4 7.9v8.3c0 .8.4 1.8 1.7 1.8 1.8 0 1.7-1.6 1.7-2.4V358c0-8-4.7-11.1-10.8-11.1-6.1 0-10.8 3-10.8 11.1v16.1c0 8.1 4.7 11.1 10.8 11.1 6.1 0 10.8-3 10.8-11.1v-1.9c0-.7 0-2.1-1.8-2.1-1.6 0-1.6 1.3-1.6 2.1z" />
				<path fill="rgb(188, 243, 249)" d="M557.9 383.5c0-.8.6-1.6 1.9-1.6h1.8c4.6 0 7.4-2.5 7.4-7.9v-15.8c0-5.4-2.8-7.9-7.4-7.9h-7.3v32.9c0 1.1-.3 1.9-1.9 1.9-.9 0-1.6-.6-1.6-1.7v-35c0-.7.6-1.2 1.6-1.2h9.2c6.1 0 10.9 3 10.9 11.1v15.8c0 8.1-4.8 11.1-10.9 11.1h-1.8c-1.6-.1-1.9-.9-1.9-1.7z" />
				<path fill="rgb(188, 243, 249)" d="M593.8 350.1c-4.8 0-7.5 2.5-7.5 7.9v16.1c0 5.5 2.6 7.9 7.7 7.9 6.2 0 6.8-3.9 7.1-6.4.1-.8.6-1.4 1.7-1.4 1.2 0 1.7.4 1.7 2.1 0 4.7-4 8.8-10.9 8.8-5.9 0-10.8-3-10.8-11.1v-16c0-8 5-11.1 10.9-11.1 6.8 0 10.7 4 10.7 8.7 0 1.7-.5 2.1-1.7 2.1s-1.7-.6-1.7-1.3c-.1-2.2-1-6.3-7.2-6.3z" />
				<path fill="rgb(188, 243, 249)" d="M638 383.4l-10.8-35.3c-.3-.8-1.1-1.2-2.1-1.2-.9 0-1.8.4-2 1.2l-10.9 35.3-.1.4c0 .9 1.1 1.5 2 1.5.6 0 1.2-.3 1.4-.9l2.4-8h9c1 0 1.9-.2 1.8-1.4-.1-1.4-.9-1.6-1.9-1.6h-8.1l6.3-20.9 9.6 31.9c.2.6.7.9 1.3.9.9 0 2.1-.7 2.1-1.5.1-.2 0-.3 0-.4z" />
			</g>
		</svg>
	</div>
);

export default Logo;
