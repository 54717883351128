import style from './style';
import { Link } from 'preact-router';

const Nav = ({ currentRoute }) => (
	<nav className={style.nav}>
		<ul>
			<li>
				<Link className={currentRoute === '/' ? style.active : ''} href="/">Qué es Why Not</Link>
			</li>
			<li>
				<Link className={currentRoute === '/episodes' ? style.active : ''} href="/episodes">Dónde Escucharnos</Link>
			</li>
			<li>
				<Link className={currentRoute === '/contact' ? style.active : ''} href="/contact">Contacto</Link>
			</li>
		</ul>
	</nav>
);

export default Nav;
