import { h } from 'preact';
import Logo from '../logo';
import Nav from '../nav';
import style from './style';

const Header = ({ currentRoute }) => (
	<header className={style.header}>
		<Logo />
		<Nav currentRoute={currentRoute} />
	</header>
);

export default Header;
