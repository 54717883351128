import { Component } from 'preact';
import * as Sentry from '@sentry/browser';

export class SentryBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, eventId: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error });
		Sentry.withScope(scope => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		return this.props.children;
	}
}