import { h } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import { Router } from 'preact-router';
import { SentryBoundary } from './sentry';
import style from './app.css';

import Header from './header';
import Content from './content';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Contact from '../routes/contact';
import Episodes from '../routes/episodes';

const titles = {
	'/': 'Inicio | WHY NOT Podcast',
	'/contact': 'Contacto | WHY NOT Podcast',
	'/episodes': 'Episodios | WHY NOT Podcast'
};
const App = () => {
	const [currentRoute, setRoute] = useState('');
	const onRouteChange = useCallback((e) => {
		setRoute(e.url);
		document.title = titles[e.url];
	}, [currentRoute]);

	return (<div id="app" className={style.app}>
		<SentryBoundary>
			<Content>
				<Header currentRoute={currentRoute} />
				<Router onChange={onRouteChange}>
					<Home path="/" />
					<Contact path="/contact" />
					<Episodes path="/episodes" />
				</Router>
			</Content>
		</SentryBoundary>
	</div>);
};

export default App;